import React from 'react'

function PageNotFound() {
    return (
        <div>
            <h1>PAGEEEEEEEEEEEE NOTTTTTTTTTTTTTTT FOUNDDDDDDDDDDDD</h1>
            <h1>PAGEEEEEEEEEEEE NOTTTTTTTTTTTTTTT FOUNDDDDDDDDDDDD</h1>
            <h1>PAGEEEEEEEEEEEE NOTTTTTTTTTTTTTTT FOUNDDDDDDDDDDDD</h1>

            <h1>PAGEEEEEEEEEEEE NOTTTTTTTTTTTTTTT FOUNDDDDDDDDDDDD</h1>
            <h1>PAGEEEEEEEEEEEE NOTTTTTTTTTTTTTTT FOUNDDDDDDDDDDDD</h1>
            <h1>PAGEEEEEEEEEEEE NOTTTTTTTTTTTTTTT FOUNDDDDDDDDDDDD</h1>
        </div>
    )
}

export default PageNotFound
