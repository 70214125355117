import React from 'react'
import '../css/Pricehound.css'
function PriceHound() {
  return (
    <div className='ph-main'>
      <div className='ph-text-center'>
        <h1>
          Pricehound
        </h1>
      </div>
      <div className='ph-main-text'>
        <p>This project was a group project for one of my University assignments, the whole point of the class was to create a piece of software, and follow the SCRUM framework, this was a 4 person group project, my group ended up choosing to do a price comparision website which was called pricehound. This project was quite large and included technologies such as React, Node.js, Express.js, Firebase, Puppeteer and Google cloud for hosting. Some features I worked on included authentication through Firebase, the wishlist feature which uses Firestore to store images and text related to the item, currency conversion, changing profile pictures and a cookies banner just to name a few. This project was a very good look at how software is actually maintained and worked on in a group setting and following the SCRUM framework helped us stay organized and keep track of what needed to be done our main organization tool was trello where we kept track of what tickets needed to be done in each sprint. This project was live but has since been taken down due to the class finishing. This project does have a Github repo where you can find <a href="https://github.com/thomasbrears/SDP_PriceHound" target="_blank">here</a>. Provided below are some images from the web application.</p>
      </div>
      <div className='even-space-ph'>
        <div className='bj-img-text'>
          <div className='bj-left'>
            <img src='https://kiwiicam.github.io/ph-img1.jpg'></img>
          </div>
          <div className='bj-right'>
            <p>
              Shown on the left is the main home page, where we have some featured products displayed, a search bar and nav-bar. This page is the landing page.
            </p>
          </div>
        </div>
        <div className='bj-img-text2'>
          <div className='bj-left2'>
            <p>
              Shown on the right is the sign in page, this utilizes Firebase authentication to allow users to sign up, sign in with either a email or password, through google or get emailed a sign in link.
            </p>
          </div>
          <div className='bj-right2'>
            <img src='https://kiwiicam.github.io/ph-img2.jpg'></img>

          </div>
        </div>
        <div className='bj-img-text'>
          <div className='bj-left'>
            <img src='https://kiwiicam.github.io/ph-img3.jpg'></img>
          </div>
          <div className='bj-right'>
            <p>
              Shown on the left is the search page, this shows a broad product search based on your input into the search bar, in this example I used laptop and what I got was a large variety of laptops, on the left is a bunch of filters and also the currency conversion option.
            </p>
          </div>
        </div>
        <div className='bj-img-text2'>
          <div className='bj-left2'>
            <p>
              Clicking on the Compare Price button will then lead you to the in depth comparision page where it compares product prices from various sources for that exact product and displays it lowest to highest price.
            </p>
          </div>
          <div className='bj-right2'>
            <img src='https://kiwiicam.github.io/ph-img4.jpg'></img>

          </div>
        </div>
      </div>
    </div>
  )
}

export default PriceHound
